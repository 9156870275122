import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Finnish train operators`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.vr.fi/cs/vr/en/frontpage"
      }}>{`VR`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Virtual_reality"
      }}>{`also VR`}</a></p>
    <h3>{`A new, better world`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.playstation.com/en-us/explore/playstation-vr/"
      }}>{`Playstation VR`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.playstation.com/en-us/games/battlezone-ps4/"
      }}>{`Battlezone`}</a></p>
    <h3>{`How to anchor sea-sickness`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.wikiwand.com/en/Virtual_reality_sickness"
      }}>{`Cybersickness`}</a></p>
    <h3>{`The uncomfortable Rift to VR scars`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.oculus.com/"
      }}>{`Oculus`}</a>{` – `}<a parentName="p" {...{
        "href": "https://thumbs.dreamstime.com/z/strange-businessman-diving-mask-snorkel-funny-office-worker-life-vest-scuba-diver-gear-office-combines-vacation-work-46889215.jpg"
      }}>{`Diver's mask`}</a></p>
    <h3>{`\\$`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.playstation.com/en-us/games/keep-talking-and-nobody-explodes-ps4/"
      }}>{`Keep talking and nobody explodes`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.playstation.com/en-us/games/here-they-lie-ps4/"
      }}>{`Here they lie`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.playstation.com/en-gb/games/vr-worlds-ps4/"
      }}>{`VR Worlds`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.playstation.com/en-gb/games/mad-max-ps4/"
      }}>{`Mad Max`}</a></p>
    <h3>{`A field with lions vs. alone in a museum with dinosaurs`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.ubisoft.com/en-US/game/star-trek-bridge-crew/"
      }}>{`Star Trek: Bridge Crew`}</a>{` – `}<a parentName="p" {...{
        "href": "https://www.oculus.com/experiences/rift/1006887936048510/"
      }}>{`Introduction to Virtual Reality`}</a></p>
    <h3>{`Expensive Tân line`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/T%C3%A2n_line"
      }}>{`Tân line`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.bbc.co.uk/programmes/p03tnxlp"
      }}>{`BBC Worldservice, The Inquiry "How will Virtual Reality Change our Lives?"`}</a>{` – `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/zombies-run!/id503519713"
      }}>{`Zombies, run!`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.penrosestudios.com/"
      }}>{`Allumette`}</a></p>
    <h3>{`Research needed`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.theguardian.com/world/2013/oct/20/young-people-japan-stopped-having-sex"
      }}>{`Japanese decline of sexual activity`}</a></p>
    <h3>{`Help`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      